.f-s {
  font-weight: 600 !important;
  font-size: 14px;
  color: #171717;
}
.active {
  color: #171717;
  font-weight: 600;
}
.inactive {
  color: #737373;
}
.border-botm {
  border-bottom: 2px solid #213e7c;
}
.ant-space-item {
  z-index: 0;
}

.ant-drawer-body {
  padding: 10px !important;
}

.custom-style {
  .ant-space {
    gap: 2px !important;
  }
}
.social-icons .ant-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #8a898d;
  border: none;
  margin-right: 8px;
  color: white;
}

.social-icons .ant-btn:hover {
  background-color: #3898ec;
}

.social-icons .ant-btn:hover .anticon {
  color: white;
}
.underline-hover:hover {
  text-decoration: none;
}

.border-bottom-hover:hover {
  border-bottom: 2px solid;
}
.underline{
  text-decoration: underline;
}

