.ant-collapse-expand-icon {
  position: absolute;
  right: 0;
}

.answer-list-intro {
  padding-top: 10px;
}

.answer-list-items {
  padding: 10px 0px 0px 16px;
}

.answer-list-items li {
  padding-left: 10px;
}
