
.custom-input-height .ant-input{
    height: 38px;
}
.ant-upload{
    background-color: white;
    /* border: 2px dashed black  !important; */
    
}
.ant-upload{
    height: 65px !important;
}