.report:hover {
  cursor: pointer;
  border: 1px solid #213e7c;
}

.prefix-icon-wrapper {
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select .ant-select-selector {
  padding-left: 100px;
}
div.ant-modal-content{
  padding: 0px !important;
}
