.variant .ant-upload {
    height: 44px !important;
}

.edit-reward .ant-upload {
    height: unset !important;
}

.ant-upload.ant-upload-drag {
    border: 1px dashed rgb(217, 217, 217) !important;
    border-radius: 6px;
}
.ant-upload-list.ant-upload-list-picture-card {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.ant-input-number-lg {
    width: 100%;
}