.hoverAffect:hover {
  background: #F6F8FB;
}

.hoverCard:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transform: scale(1);
}

.ant-popover {
  z-index: 0;
}

/* .ant-space-vertical{
    width: 404px;
  } */
.ant-upload {
  /* width: 100% !important; */
  height: 127px !important;
}

.drop-down-style {
  margin-top: 18px !important;
  margin-left: 17px !important;
}

.slider-main-div {
  margin-top: 40px;
  width: 345px;
  margin-left: 45px;
  margin-right: 25;

  .ant-slider-rail {
    height: 7px;
  }

  .ant-slider-handle {
    height: 30px;
    width: 30px;
  }
}

.drop-down-style-second {
  padding-left: 17px;
  padding-top: 8px;
}

.redeem-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.redeem-button-container:hover {
  opacity: 1;
}
