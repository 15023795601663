.pagination-dropdown-custom {
    .ant-select-dropdown {
        top: -140px !important;
        box-sizing: border-box;
        position: absolute;
    }
}

.container {
    overflow-x: auto;
    white-space: nowrap;
}

.container::-webkit-scrollbar {
    display: none;
}

.pagination-component {
    display: flex;
    width: 100%;
}

.pagination-component .ant-pagination-total-text {
    flex: 1;
}

.ant-pagination {
    padding: 0px 20px;
}

.slick-dots li {
    background-color: black;
}

.slick-active button {
    background-color: darkgray !important;
}

.slick-dots li {
    border-radius: 1rem;
}

.slick-dots-bottom {
    bottom: 40px !important;
}