@import 'typeface-inter';

body {
  font-family: 'Inter', sans-serif;
}

li.ant-menu-item{
  padding-left: 12px !important;
}

li.ant-menu-item-selected.ant-menu-item-only-child{
  border-radius: 0px !important;
}

/* Modal styling */
span.ant-modal-close-x{
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  width: 40px !important;
  height: 40px !important;
  background: #FFFFFF;
  border: 1px solid #CFD9E0;
  border-radius: 6px !important;

}
.modal-bottom-second-text-style{
  font-family: 'Arial';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;
text-align: center;

/* Grey 500 */

color: #737373;
}
.modal-bottom-first-text-style{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 29px;
/* identical to box height */

text-align: center;

/* Grey 900 */

color: #171717;
}
.modal-price-2text-style{
  margin-top: 6px;
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
/* identical to box height */


/* Grey 500 */

color: #737373;
}
.modal-price-text-style{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
/* identical to box height */


/* Grey 900 */

color: #171717;
}
.modal-text-style{
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #171717;
}
.modal-2text-style{
  margin-top: 8px;
  margin-left: 8px;
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */


/* Secondary Blue */

color: #4A90E3;
}
.carousel-style{
  padding-left: 130px;
  padding-right: 130px;
  padding-top: 60px;
  /* padding-bottom: 125px; */
}
.carousel-mobile-style{
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 80px;
}

button.ant-modal-close{
  width: 40px !important;
  height: 40px !important;  
}

div.ant-modal-header{
  border-bottom: 1px solid #CFD9E0;
  height: 70px !important;
  padding-top: 24px !important;
  padding-left: 24px !important;
  padding-right  : 24px !important;


}

div.ant-modal-body{
  padding: 0px !important;
 
}
.card-cover-image {
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  overflow: hidden;
}
table {
  border-collapse: separate;
  border-spacing: 1px 10px;
}


.login-cancelled-message{
  text-align: center;
  width: 100%;
  color: red;
  padding: 10px 0px;
}

.flex{
  display: flex;
}
.items-center{
  align-items: center;
}
.justify-between{
  justify-content: space-between;
}
.justify-center{
  justify-content: center;
}
.flex-col{
  flex-direction: column;
}
.w-full{
  width: 100%;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #4279bd;
  opacity: 0.6;
  border-radius: 8px;
  border: none;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4279bd;
  opacity: 0.8;
}

.front-office-layout ::-webkit-scrollbar-thumb {
  background-color: #4279bd;
  border-radius: 8px;
  border: none;
  background-clip: content-box;
}

.front-office-layout ::-webkit-scrollbar-thumb:hover {
  background-color: #4279bd;
}

.hide-spinner-body :where(.css-dev-only-do-not-override-qu8mps).ant-spin-nested-loading .ant-spin-blur{
  opacity: 0;
  transition: none !important;
}