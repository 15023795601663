.table-component {
    width: 100%;
}

.table-component .ant-pagination-total-text {
    flex: 1;
}

.ant-pagination {
    padding: 0px 20px;
}